<template>
  <ion-page id="welcome">
    <ion-content class="ion-text-center ion-no-padding" scrollY="false">
      <div class="container">
        <div class="wrap">
          <h1 class="self">Interactive Olympic Pins&nbsp;Catalogue</h1>
          <div class="divider"></div>
          <div class="divider-small"></div>
          <div class="h-wrap">
            <h1 class="h-color">
              <span class="blue">AICO</span><span class="red">L</span><span class="green">Y</span><span class="yellow">M</span><span>PINS</span>
            </h1>
            <h1 class="h-color">
              <span class="red">CATALOGUE</span>
            </h1>
            <h1 class="h-color">
              <span class="blue">A</span><span class="red">O</span><span class="green">P</span><span>C</span>
            </h1>
          </div>
          <div class="upper-logos-wrapper">
              <img @click="openSponsoreLink('https://aicolympic.org')" class="w-50 cursor-pointer" src="/assets/images/new/aico.png" alt="">
              <div class="splitter"></div>
              <img @click="openSponsoreLink('https://www.dragoparis.fr/jeux-olympiques-et-paralympiques-paris-2024')" class="w-50 cursor-pointer" src="/assets/images/new/drago.png" alt="">
          </div>
          <img class="image-text" src="/assets/images/new/text.png" alt="">
          <div class="upper-logos-wrapper">
            <img @click="openSponsoreLink('https://olympics.com/')" class="w-50 cursor-pointer" src="/assets/images/new/olympic_en.png" alt="">
            <div class="splitter"></div>
            <img @click="openSponsoreLink('https://olympics.com/')" class="w-50 cursor-pointer" src="/assets/images/new/olympic_fr.png" alt="">
          </div>

          <ion-button @click="navigate" router-direction="none" class="self --default">Welcome</ion-button>

          <div class="divider small"></div>

          <p>© 2022 All rights reserved</p>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { inject } from 'vue'

export default {
  setup () {
    const ionRouter = inject('navManager')

    return {
      ionRouter
    }
  },
  methods: {
    navigate() {
      if (localStorage.getItem('onboardingDone') == 'true') {
        return this.ionRouter.navigate({
          routerDirection: 'none',
          routerLink: '/app/country-list'
        })
      }
      this.ionRouter.navigate({
        routerDirection: 'none',
        routerLink: '/app/intro'
      })
    },
    openSponsoreLink(link) {
      window.open(link)
    }
  }
}
</script>
<style lang="sass" scoped>
.splitter
  width: 2px
  height: 80px
  border-radius: 100px
  background: rgba(190, 190, 190, .4)
.red
  color: rgb(243, 30, 69)
.green
  color: rgb(0, 158, 72)
.blue
  color: #0081C8
.yellow
  color: rgb(255, 167, 46)
.h-color
  margin: 0
  padding: 0
h1
  font-weight: normal
.h-wrap
  margin: 15px 0
.container
  display: flex
  align-items: center
  height: 100%
.wrap
  width: 100%
  img
    cursor: pointer
.self
  width: 300px
  margin: 20px auto
.divider
  height: 2px
  background: rgba(154, 154, 154, 0.4)
.divider-small
  width: 100px
  margin: 0 auto
  height: 2px
  background: black
  position: relative
  top: -2px
  z-index: 100
.img-margin
  width: 278px
  display: flex
  margin: 0px auto 5px
.image-text
  width: 200px
.upper-logos-wrapper
  width: 220px
  display: flex
  margin: 10px auto 5px
  justify-content: space-between
  align-items: center
  img
    max-width: 50%
    align-items: center
    max-height: fit-content
.w-50
  width: 49%
h1
  font-size: 37px

.divider.small
  width: 90%
  max-width: 300px
  margin: 10px auto
  height: 1px !important

@media only screen and (max-width: 768px)
  h1
    font-size: 30px
@media only screen and (min-width: 768px)
  ion-router-outlet > .ion-page
    margin-left: 0 !important
</style>
